import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Body, Headline, Hr } from '@sumup/circuit-ui';
import { Tooltip } from '@sumup/circuit-ui/legacy';
import { Info } from '@sumup/icons';

export const OverviewWrapper = styled('section')<{
  noMargin: boolean;
  usedInModal: boolean;
}>(
  ({ usedInModal, noMargin = false }) => css`
    background-color: ${!usedInModal && 'var(--cui-bg-subtle)'};
    border-radius: var(--cui-border-radius-mega);
    padding: ${!usedInModal && 'var(--cui-spacings-giga)'};
    margin-top: var(--cui-spacings-giga);

    ${noMargin && 'margin: 0;'};

    & + section {
      margin-top: var(--cui-spacings-giga);
    }

    ${!usedInModal &&
    css`
      &:last-of-type:not(:first-of-type) {
        margin-bottom: var(--cui-spacings-zetta);
      }
    `}
  `,
);

export const StyledHeadline = styled(Headline)`
  letter-spacing: -0.03em;
  margin-bottom: var(--cui-spacings-giga);
`;

export const DescriptionContainer = styled('div')<{
  alignItems?: string;
  usedForCart?: boolean;
}>(
  ({ usedForCart, alignItems = 'center' }) => css`
    display: flex;
    flex-direction: row;
    align-items: ${alignItems};
    justify-content: space-between;
    margin-bottom: var(--cui-spacings-mega);

    &:last-of-type {
      margin-bottom: 0;
    }

    ${usedForCart &&
    css`
      margin-left: var(--cui-spacings-mega);
      margin-right: var(--cui-spacings-mega);
    `}
  `,
);

export const StyledHr = styled(Hr)`
  border-top: 1px solid var(--cui-border-subtle);
  margin: var(--cui-spacings-mega) 0;
`;

export const TotalAmountContainer = styled(DescriptionContainer)(
  () => css`
    letter-spacing: -0.03em;

    > * {
      font-size: 18px;
    }
  `,
);

export const FreeDeliveryText = styled(Body)`
  color: var(--cui-border-success);
  font-weight: var(--cui-font-weight-bold);
`;

export const TooltipContainer = styled.div<{ alignedAtBottom?: boolean }>(
  ({ alignedAtBottom = false }) => css`
    position: relative;
    top: ${alignedAtBottom ? '0' : 'calc(var(--cui-spacings-mega) * -1);'};
  `,
);

export const StyleInvertedTooltip = styled(Tooltip)(
  () => css`
    background-color: var(--cui-bg-strong);
    color: var(--cui-fg-on-strong);
  `,
);

export const InfoIcon = styled(Info)<{ usedInPlanCard?: boolean }>(
  ({ usedInPlanCard = false }) => css`
    margin: ${usedInPlanCard
      ? 'var(--cui-spacings-bit)'
      : 'var(--cui-spacings-byte)'};
    vertical-align: middle;

    @media print {
      display: none;
    }
  `,
);

export const PrintableFeeConditions = styled(Body)(
  () => css`
    display: none;

    @media print {
      display: block;
    }
  `,
);

export const PlanFeeContainer = styled('div')(
  () => css`
    display: flex;
    flex-direction: column;

    margin-bottom: var(--cui-spacings-mega);

    &:last-of-type {
      margin-bottom: 0;
    }
  `,
);

export const FeeRateContainer = styled('div')(
  () => css`
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--cui-spacings-bit);
  `,
);

export const TransactionTypes = styled(Body)`
  color: var(--cui-fg-subtle);
`;
