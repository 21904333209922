import { formatCurrencyWithLocale } from 'utils/currency';

export type Installment = {
  id: string;
  billing_platform: string;
  amount_with_taxes_float: number;
  date: Date;
};

export type PaymentPlan = {
  billing_frequency: string;
  installment_count: number;
  installments: Installment[];
};

export const formatFirstInstallmentAmount = (
  plan: PaymentPlan,
  locale: string,
): string =>
  formatCurrencyWithLocale(
    plan.installments[0].amount_with_taxes_float,
    locale,
  );

export const formatFutureInstallmentAmount = (
  plan: PaymentPlan,
  locale: string,
): string => {
  const amount = formatCurrencyWithLocale(
    plan.installments[1].amount_with_taxes_float,
    locale,
  );

  const count = plan.installment_count - 1;

  return `${count}x ${amount}`;
};
